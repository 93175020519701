import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { trackEvent, trackException } from '@dimatech/shared/lib/tracking';
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from 'components/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EventType, SurveyStatus } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { saveAnswers, selectIsSaving } from './api/answerSlice';
import {
  selectCanSelectEntity,
  selectCurrentPageIndex,
  selectEntityId,
  selectPage,
  selectSurvey,
  surveyActions,
} from './api/surveySlice';
import { getInvalidQuestions } from './validation';

export const FooterButtons = ({
  resetSaveTimer,
  setMsgAnyProblem,
  setMsgNotAllAnswers,
}: {
  resetSaveTimer: () => void;
  setMsgAnyProblem: React.Dispatch<React.SetStateAction<boolean>>;
  setMsgNotAllAnswers: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const survey = useAppSelector(selectSurvey);
  const currentPageIndex = useAppSelector(selectCurrentPageIndex);
  const entityId = useAppSelector(selectEntityId);
  const canSelectEntity = useAppSelector(selectCanSelectEntity);
  const page = useAppSelector(selectPage);
  const isSaving = useAppSelector(selectIsSaving);
  const dispatch = useAppDispatch();

  const prevPage = () => {
    if (!survey || !page || page.isFirst) {
      return;
    }

    trackEvent(EventType.SurveyPreviousPage, {
      pageNum: currentPageIndex - 1,
      preview: survey.isReadOnly,
    });

    dispatch(surveyActions.previousPage());
  };

  const nextPage = async () => {
    dispatch(surveyActions.updateHasValidated(false));

    if (!survey || !page) {
      return;
    }

    if (!page.isValid || (canSelectEntity && !entityId)) {
      const invalidQuestions = getInvalidQuestions(page);

      dispatch(surveyActions.updateHasValidated(true));
      dispatch(surveyActions.updateInvalidQuestions(invalidQuestions));

      setMsgNotAllAnswers(true);

      return;
    }

    trackEvent(EventType.SurveyNextPage, {
      pageNum: currentPageIndex + 1,
      preview: survey.isReadOnly,
    });

    if (survey.isReadOnly) {
      if (page.isLast) {
        dispatch(surveyActions.updateStatus(SurveyStatus.Finished));
      } else {
        dispatch(surveyActions.updateStatus(SurveyStatus.Started));
        dispatch(surveyActions.nextPage());
      }

      return;
    }

    dispatch(
      saveAnswers({
        questions: [...page.questions],
        token: survey.token,
        entityId: canSelectEntity ? survey.entityId : undefined,
      })
    )
      .unwrap()
      .then((status) => {
        dispatch(surveyActions.updateStatus(status));

        if (page.isLast) {
          trackEvent(EventType.SurveyComplete);
        } else {
          resetSaveTimer();
          trackEvent(EventType.SurveySave);
          dispatch(surveyActions.nextPage());
        }
      })
      .catch((error: Error) => {
        trackException(error, 'saveAnswers', {
          token: survey.token,
        });

        setMsgAnyProblem(true);
      });
  };

  return page ? (
    <div className="survey-footer-buttons">
      {!page.isFirst && (
        <ButtonTertiary
          className="active"
          onClick={prevPage}
          type="button"
          id="buttonPrevious"
        >
          {t('Survey.Button.Previous')}
        </ButtonTertiary>
      )}

      {!page.isLast && !isSaving && (
        <ButtonSecondary
          className={page.isValid ? 'active' : ''}
          onClick={nextPage}
          type="button"
          id="buttonNext"
        >
          {t('Survey.Button.Next')}
        </ButtonSecondary>
      )}

      {!page.isLast && isSaving && (
        <ButtonSecondary className="active" type="button" disabled={true}>
          <LoaderSmall style={{ display: 'inline-block' }} />{' '}
          {t('Survey.Button.Saving')}
        </ButtonSecondary>
      )}

      {page.isLast && (
        <ButtonPrimary
          className={page.isValid ? 'active' : ''}
          onClick={nextPage}
          type="button"
          id="buttonSubmit"
        >
          {t('Survey.Button.Submit')}
        </ButtonPrimary>
      )}
    </div>
  ) : null;
};

FooterButtons.displayName = 'FooterButtons';
